import * as React from 'react';

import { navigate } from 'gatsby';
import { Spinner } from 'react-bootstrap';
import '../scss/styles.scss';

const PressContactPage = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);

  return (
    <main>
      <div className="full-height center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </main>
  );
};

export default PressContactPage;
